import loadDataList from '../Load/LoadDataList/LoadDataList.vue'
import loadDataError from '../Load/LoadDataError/LoadDataError.vue'

export default {
    components: {
        loadDataList,
        loadDataError
    },
    data () {
        return {
            showError: false,
            newLoad: false,
            logData: "",
            attachment: { name: "" },
            user: "",
            vademecumData: []
          }
        },
        methods: {
            openFileChooser() {
                this.$refs.file.click();
              },
              async handleError(error) {
                this.$refs.loadDataError.handleDataErrors(error);
              },
              updateError (data) {
                  alert()
                this.newLoad =  true;

              },
              rowClick(){
                this.showError =  true
                this.newLoad = false
              }
        },
}