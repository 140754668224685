var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "v-container",
    { attrs: { "fill-height": "", fluid: "", "grid-list-xl": "" } },
    [
      _c(
        "v-layout",
        { attrs: { "justify-center": "", wrap: "" } },
        [
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.newLoad && !_vm.showError,
                  expression: "!newLoad && !showError",
                },
              ],
              attrs: { md12: "" },
            },
            [
              _c(
                "material-card",
                { attrs: { color: "primary", title: "Carga despachos" } },
                [
                  _c(
                    "v-flex",
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { right: "", color: "success", round: "" },
                          on: {
                            click: function ($event) {
                              _vm.newLoad = true
                            },
                          },
                        },
                        [_vm._v("Nueva carga")]
                      ),
                    ],
                    1
                  ),
                  _c(
                    "v-flex",
                    [
                      _c("load-data-list", {
                        ref: "loadDataList",
                        on: { rowClick: _vm.rowClick },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("v-flex"),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.newLoad,
                  expression: "newLoad",
                },
              ],
              attrs: { md12: "" },
            },
            [
              _c(
                "v-flex",
                [
                  _c(
                    "material-card",
                    [
                      _c("v-text-field", {
                        attrs: { readonly: "", disabled: true },
                        model: {
                          value: _vm.attachment.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.attachment, "name", $$v)
                          },
                          expression: "attachment.name",
                        },
                      }),
                      _c("input", {
                        ref: "file",
                        staticStyle: { display: "none" },
                        attrs: {
                          type: "file",
                          id: "file",
                          name: "file",
                          accept: ".xlsx",
                        },
                      }),
                      _c(
                        "v-flex",
                        { attrs: { md5: "" } },
                        [
                          _c(
                            "v-btn",
                            {
                              attrs: { color: "success", round: "" },
                              on: {
                                click: function ($event) {
                                  return _vm.openFileChooser()
                                },
                              },
                            },
                            [_vm._v("Nueva Carga despachos")]
                          ),
                          _c(
                            "v-btn",
                            {
                              staticClass: "ma-1",
                              attrs: { color: "error", round: "" },
                              on: {
                                click: function ($event) {
                                  _vm.newLoad = false
                                },
                              },
                            },
                            [_vm._v("Cancelar")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-flex",
                [
                  _c(
                    "material-card",
                    [
                      _c("loadDataError", {
                        ref: "loadDataError",
                        on: { updateError: _vm.updateError },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-flex",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.showError,
                  expression: "showError",
                },
              ],
              attrs: { md12: "" },
            },
            [
              _c(
                "material-card",
                {
                  attrs: { color: "primary", title: "Errores carga despachos" },
                },
                [
                  _c("loadDataError", {
                    ref: "loadDataError",
                    on: { updateError: _vm.updateError },
                  }),
                  _c(
                    "v-flex",
                    { attrs: { md5: "" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "ma-1",
                          attrs: { color: "error", round: "" },
                          on: {
                            click: function ($event) {
                              ;(_vm.newLoad = false), (_vm.showError = false)
                            },
                          },
                        },
                        [_vm._v("Cancelar")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }